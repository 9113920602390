






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    NewsNav: () => import(/* webpackChunkName: "cms" */'@/components/business/pc/news/InsNewsNav.vue'),
    Location: () => import(/* webpackChunkName: "cms" */'@/components/base/pc/InsLocation.vue')
  }
})
export default class InsCmsContent extends Vue {
  NewsNav: string = 'NewsNav';
  CateName: string = '';
  content:any[]=[];
  created () {
    // if (process.env.NODE_ENV === 'production') {
    //   try {
    //     let seoData = require('../../../../sdk/common/SeoData');
    //     let keywords = document.createElement('meta');
    //     keywords.setAttribute('name', 'keywords');
    //     keywords.setAttribute('content', seoData.seoItem['cms' + this.id].keyword);
    //     document.head.appendChild(keywords);
    //     let description = document.createElement('meta');
    //     description.setAttribute('name', 'description');
    //     description.setAttribute('content', seoData.seoItem['cms' + this.id].description);
    //     document.head.appendChild(description);
    //     document.title = seoData.seoItem['cms' + this.id].title;
    //     document.dispatchEvent(new Event('render-event'));
    //   } catch (e) {
    //     console.log('當前頁面無需SEO。');
    //   }
    // }
    let id = this.$route.params.id as string;
    let that = this;
    let conf;
    if (isNaN(Number(id))) conf = { Key: id, IsMobile: false };
    else conf = { ContentId: id, IsMobile: false };
    // this.$Api.cms.getContentByDevice(conf).then((result) => {
    //   this.content = result;
    //   this.$nextTick(() => {
    //     if (result.Title) document.title = result.Desc;
    //   });
    //   this.CateName = result.CMS.Title;
    //   this.$HiddenLayer();
    // });
    this.$Api.cms.getContentByDevice(conf).then(result => {
      this.$nextTick(() => {
        if (result.CMS.Title) document.title = result.CMS.Title;
      });
      this.content = result.CMS;
      this.CateName = result.CMS.Name;
    });
  }
  mounted () {

  }
  @Watch('$route', { deep: true })
  onRouteChange () {
    let id = this.$route.params.id as string;
    let conf;
    if (isNaN(Number(id))) conf = { Key: id, IsMobile: false };
    else conf = { ContentId: id, IsMobile: false };
    // this.$Api.cms.getContentByDevice(conf).then((result) => {
    //   this.content = result;
    //   this.$nextTick(() => {
    //     if (result.Title) document.title = result.Desc;
    //   });
    //   this.CateName = result.CMS.Title;
    //   this.$HiddenLayer();
    // });
    this.$Api.cms.getContentByDevice(conf).then(result => {
      this.$nextTick(() => {
        if (result.CMS.Title) document.title = result.CMS.Title;
      });
      this.content = result.CMS;
      this.CateName = result.CMS.Name;
    });
  }
  get id () {
    return this.$route.params.id;
  }
}

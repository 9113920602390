
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { CMSPager, CmsResult } from '@/model/cmsResult';
@Component
export default class InsNewsNav extends Vue {
  @Prop() private NewsNavStr!: any;
  @Prop() private title!: string;
  @Prop() private titiebg!: string;
  @Prop() private hoverbg!: string;
  @Prop() private showSlideMeun!: boolean;
  defaultProps: any = {
    children: 'Children',
    label: 'Name'
  };
  data:any[]=[];
  created () {
    this.$Api.cms.getCategoryTree({ id: 40101 }).then((result) => {
      this.data = result;
    });
  }

  handleNodeClick (data) {
    let id = data.Id;
    let title = data.Name;
    let link = '/news/newsmain/';
    this.$store.dispatch('setIdsFun', { id, title });
    var url = location.href;
    if (url.indexOf('cmsContent') >= 1) {
      this.$router.push({ path: link });
    }
  }
}



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BannerResult } from '@/model/bannerResult';
@Component({
  components: {
    NewsNav: () => import(/* webpackChunkName: "cms" */ '@/components/business/mobile/news/InsNewsNav.vue'),
    Location: () => import(/* webpackChunkName: "cms" */'@/components/base/mobile/InsLocation.vue')
  }
})
export default class InsCmsContent extends Vue {
  NewsNav: string = 'NewsNav';
  CateName: string = '';
  content: any[] = [];
  private bannerList:BannerResult[] = [];
  swiperOption: object = {
    autoplay: {
      disableOnInteraction: false
    }
  };
  created () {
    let id = this.$route.params.id as string;
    let that = this;
    let conf;
    if (isNaN(Number(id))) conf = { Key: id, IsMobile: true };
    else conf = { ContentId: id, IsMobile: true };
    this.$Api.cms.getContentByDevice(conf).then(result => {
      this.$nextTick(() => {
        if (result.CMS.Title) document.title = result.CMS.Title;
      });
      this.content = result.CMS;
      this.CateName = result.CMS.Name;
    });
    if (id === '20292') {
      this.$Api.promotion.getPromotion('Home', 10).then((result) => {
        this.bannerList = result.Promotion.BannerList;
      });
    }
  }
  @Watch('$route', { deep: true })
  onIdChange () {
    let id = this.$route.params.id as string;
    let conf;
    if (isNaN(Number(id))) conf = { Key: id, IsMobile: true };
    else conf = { ContentId: id, IsMobile: true };
    this.$Api.cms.getContentByDevice(conf).then(result => {
      this.$nextTick(() => {
        if (result.CMS.Title) document.title = result.CMS.Title;
      });
      this.content = result.CMS;
      this.CateName = result.CMS.Name;
    });
  }
}
